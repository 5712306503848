// BANNER
.banner {
	display: block;
	position: relative;
	height: 100vh;
	.row {height: 100vh;}
}
.banner-title {
	background-color: rgba($color-2, .75);
	display: inline-block;
    border-radius: 4px;
	padding: 20px 30px;
	position: absolute;
	bottom: 120px;
	left: 0;
	width: 100%;
	margin: 0 0%;
	// margin-top: 50%;
	text-align: center;
	h3 {
	   text-transform: uppercase;
	   font-weight: 700;
	   font-size: .75em;
	   color: $color-3;
	   span {
		   color: $color-4;
		   font-weight: 100;
	   }
	}
	h1 { 
	   font-weight: 100;
	   text-align: center;
	   font-size: 2em;
	   color: $color-4;
   }
}
.banner .banner-title {
	opacity: 0;
	animation: .75s ease-out forwards slideUpFade;
	animation-delay: 2000ms;
}
@keyframes slideUpFade {
	0% {
		transform: translateY(100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
#subheader.content-block {
	padding-bottom: 0;
	.banner-title {
		margin-top: 30px;
		width: 100%;
		h3 {
			font-size: 1em;
			margin: 10px auto 10px;
		}
		h1 {font-size: 1.8em;}
	}
}

@media (orientation: landscape) {}
@media (orientation: portrait) {}

@media only screen and (min-width: 576px) {
  .banner {
		h1 {font-size: 2.25em;}
		h3 {font-size: .8em;}
	}
	#subheader {
		.banner-title {
			margin-top: 30px;
			h3 {font-size: 1em;}
			h1 {font-size: 1.8em;}
		}
	}
}
@media only screen and (min-width: 768px) {
  .banner {
		h1 {font-size: 3em;}
		h3 {font-size: 1.1em;}
	}
	#subheader {
		.banner-title {
			margin-top: 20px;
			h3 {font-size: .65em;}
			h1 {font-size: 2em;}
		}
	}
}
@media only screen and (min-width: 922px) {
  .banner-title {
		bottom: 120px;
		left: 0;
		width: 90%;
		margin: 0 5%;
	}
	.banner {
		h1 {font-size: 3.5em;}
		h3 {font-size: 1.3em;}
	}
	#subheader {
		.banner-title {
			margin-top: 0;
			h3 {font-size: .85em;}
			h1 {font-size: 2.5em;}
		}
	}
}
@media only screen and (min-width: 1200px) {
  .banner-title {
		bottom: 150px;
		left: 0;
		width: 80%;
		margin: 0 10%;
	}
	.banner {
		h1 {font-size: 4em;}
		h3 {font-size: 1.4em;}
	}
}